.side {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 400px;
  border-left: 1px solid #3E474F;
  font-family: 'Oxanium', serif;
  font-size: 12pt;
  color: #D6EEF5;
  padding: 2em;
  background-color: rgba(41, 54, 62, 0.5);
}

h2 {
  text-align: center;
  font-variant: small-caps;
  font-family: "Stargate", serif;
  font-size: 24pt;
  font-weight: bold;
  border-bottom: 1px solid #D6EEF5;
  margin: 0px;
}
