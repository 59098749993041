@font-face {
  font-family: 'Oxanium';
  src: local('Oxanium'), url(./assets/fonts/Oxanium-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Stargate';
  src: local('Stargate'), url(./assets/fonts/STARGATE.TTF) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  text-align: justify;
  text-indent: 1em;
}
